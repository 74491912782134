exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-blogs-js": () => import("./../../../src/pages/ja/blogs.js" /* webpackChunkName: "component---src-pages-ja-blogs-js" */),
  "component---src-pages-ja-js": () => import("./../../../src/pages/ja.js" /* webpackChunkName: "component---src-pages-ja-js" */),
  "component---src-pages-ja-tours-js": () => import("./../../../src/pages/ja/tours.js" /* webpackChunkName: "component---src-pages-ja-tours-js" */),
  "component---src-pages-storyblok-entry-full-slug-js": () => import("./../../../src/pages/{storyblokEntry.full_slug}.js" /* webpackChunkName: "component---src-pages-storyblok-entry-full-slug-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

